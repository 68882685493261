import type { PaymentProviders } from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import type { GetPaymentServiceInfoResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { deleteCookie, setCookie } from '@patrianna/shared-utils/cookie'
import { getWithdrawSettings } from '@patrianna-payments/shared-store/redeem/store/actions'
import { getIsNewRedeemPolicyEnabledSelector } from '@patrianna-payments/shared-store/redeem/store/selectors'

import { SC_ENABLED } from 'app/constants/cookies'
import type { TypedThunk } from 'store'

import { actions } from './slice'

export const {
  togglePWAMessage,
  saveLocationData,
  savePasswordPolicy,
  setSweepstakeEnabled,
  initApp,
  savePaymentProviders,
  saveKYCRequiredField,
  setSoftKycAutoCompleteAddressEnabled,
  saveRedeemPolicy,
  setIsStickyPlayButtonsShowed,
} = actions

export const getAvailablePaymentProvidersRequest =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    gateway
      .emit<GetPaymentServiceInfoResponse>(
        {
          type: 'payment.GetPaymentServiceInfoRequest',
        },
        // @todo: remove this flag after all clients will be updated
        true
      )
      .then((res) => {
        const { withdrawProviders, purchaseProviders, softKycRequiredEnabled } = res
        dispatch(savePaymentProviders({ paymentProviders: { withdrawProviders, purchaseProviders } }))

        if (softKycRequiredEnabled) {
          dispatch(saveKYCRequiredField({ softKycRequiredEnabled }))
        }

        // dispatch(saveAppsConfigs(body.apps))
      })
      .catch((err) => {
        dispatch(
          errorHandler(err, {
            type: 'payment.GetPaymentServiceInfoRequest',
          })
        )
      })
  }

export const enabledSweepstake =
  (enabled: boolean): TypedThunk =>
  (dispatch) => {
    if (enabled) {
      setCookie(SC_ENABLED, '1', { path: '/', expires: new Date(Date.now() + 31536000000) })
    } else {
      deleteCookie(SC_ENABLED)
    }
    dispatch(setSweepstakeEnabled({ enabled }))
  }

export const saveSoftKYCOptions =
  ({
    softKycAutoCompleteAddress,
    softKycRequiredEnabled,
  }: {
    softKycRequiredEnabled: boolean
    softKycAutoCompleteAddress: boolean
  }): TypedThunk =>
  (dispatch) => {
    if (softKycAutoCompleteAddress) {
      dispatch(setSoftKycAutoCompleteAddressEnabled({ softKycAutoCompleteAddress }))
    }
    if (softKycRequiredEnabled) {
      dispatch(saveKYCRequiredField({ softKycRequiredEnabled }))
    }
  }

export const saveOrGetPaymentProviders =
  (paymentProviders: PaymentProviders): TypedThunk =>
  (dispatch, getState) => {
    const isNewRedeemPolicyEnabled = getIsNewRedeemPolicyEnabledSelector(getState())

    dispatch(savePaymentProviders({ paymentProviders }))

    if (isNewRedeemPolicyEnabled) {
      dispatch(getWithdrawSettings())
    }
  }
