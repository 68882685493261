import { useTranslation } from '@patrianna/core-components'

import { Link } from 'components/Link'

import classes from './styles.module.scss'

const SUPPORT_LINK = process.env.ZENDESK_SUPPORT_LINK

export default function ContactUsButton() {
  const t = useTranslation()

  if (SUPPORT_LINK) {
    return (
      <Link href={SUPPORT_LINK} passHref className={classes.root} target='_blank'>
        {t('common.contact_us').toLowerCase()}
      </Link>
    )
  }

  return null
}
