import type { IBuilder } from '@patrianna-payments/analytics/builder/events'
import { BuilderEventServiceBase } from '@patrianna-payments/analytics/builder/events'
import * as Sentry from '@sentry/nextjs'

const ENABLE_LOG_MESSAGES = process.env.ENABLE_LOG_MESSAGES || ''

class BuilderEventService extends BuilderEventServiceBase {
  protected getPackage = async (): Promise<IBuilder> => {
    const { builder } = await import('@builder.io/react')

    return builder
  }
}

const builder = new BuilderEventService(process.env.BUILDER_KEY, ENABLE_LOG_MESSAGES, Sentry.captureMessage)

export default builder
