import type {
  LocationData,
  PasswordPolicyType,
  PaymentProviders,
} from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import type { RedeemPolicyType } from '@patrianna-payments/shared-store/redeem/types'
import { openZendeskChatByCommand } from '@patrianna-payments/zendesk'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { AppConfigState } from 'store/types/AppConfigModule'

export const openZendeskChatByCommandActionName = 'openZendeskChatByCommandAction'

export const initialState: AppConfigState = {
  init: false,
  sweepstakeEnabled: false,
  redeemPolicy: null,
  passwordPolicy: {
    min: 8,
    max: 20,
    lowerCase: 1,
    upperCase: 1,
    special: 0,
    digits: 1,
    shouldNotMatchEmail: true,
  },
  paymentProviders: null,
  isPWAMessageOpen: false,
  location: null,
  softKycRequiredEnabled: null,
  softKycAutoCompleteAddress: false,
  isStickyPlayButtonsShowed: false,
}

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    initApp: (state) => {
      state.init = true
    },

    savePasswordPolicy: (state, action: PayloadAction<{ passwordPolicy: PasswordPolicyType }>) => {
      state.passwordPolicy = action.payload.passwordPolicy
    },

    saveLocationData: (state, action: PayloadAction<{ location: LocationData }>) => {
      state.location = action.payload.location
    },

    setSweepstakeEnabled: (state, action: PayloadAction<{ enabled: boolean }>) => {
      state.sweepstakeEnabled = action.payload.enabled
    },

    togglePWAMessage: (state, action: PayloadAction<{ isPWAMessageOpen: boolean }>) => {
      state.isPWAMessageOpen = action.payload.isPWAMessageOpen
    },

    setSoftKycAutoCompleteAddressEnabled: (state, action: PayloadAction<{ softKycAutoCompleteAddress: boolean }>) => {
      state.softKycAutoCompleteAddress = action.payload.softKycAutoCompleteAddress
    },

    savePaymentProviders: (state, action: PayloadAction<{ paymentProviders: PaymentProviders }>) => {
      state.paymentProviders = action.payload.paymentProviders
    },

    saveKYCRequiredField: (state, action: PayloadAction<{ softKycRequiredEnabled: boolean }>) => {
      state.softKycRequiredEnabled = action.payload.softKycRequiredEnabled
    },

    saveRedeemPolicy: (state, action: PayloadAction<{ policy: RedeemPolicyType }>) => {
      state.redeemPolicy = action.payload.policy
    },

    setIsStickyPlayButtonsShowed: (state, action: PayloadAction<{ isStickyPlayButtonsShowed: boolean }>) => {
      state.isStickyPlayButtonsShowed = action.payload.isStickyPlayButtonsShowed
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openZendeskChatByCommandActionName, openZendeskChatByCommand)
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof appConfigSlice.actions>>
export const { actions } = appConfigSlice
