import type { GetVisitedPromotionsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetVisitedPromotionsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { createAction } from '@reduxjs/toolkit'

import type { TypedThunk } from 'store'
import { createAppAsyncThunk } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  areVisitedPromotionsLoaded,
  getUnreadPromotionsAmount,
  getVisitedPromotionIds,
  isUnvisitedPromotionsIconDismissed,
} from 'store/modules/promotions'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

import { setUnvisitedPromotionsIconDismissed } from './slice'

export const addVisitedPromotionToStore = createAction<string>('addVisitedPromotionToStore')

export const setPromotionAsVisited =
  (promotionId: string): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    const state = getState()
    const visitedPromotionsLoaded = areVisitedPromotionsLoaded(state)
    const visitedPromotionIds = getVisitedPromotionIds(state)
    const loggedIn = isLoggedInSelector(state)
    const sweepstakeEnabled = sweepstakeEnabledSelector(state)

    if (loggedIn && promotionId && visitedPromotionsLoaded && !visitedPromotionIds.includes(promotionId)) {
      dispatch(addVisitedPromotionToStore(promotionId))

      gateway
        .emit<unknown>({
          type: 'UpdateVisitedPromotionsRequest',
          promo: [
            {
              id: promotionId,
              // TODO remove "mode" once it is removed from corresponding Backend endpoint
              mode: sweepstakeEnabled ? 'SC' : 'GC',
            },
          ],
        })
        .catch((error) => console.log(`Error while fetching content from, ${setPromotionAsVisited.name}`, error))
    }
  }

export const dismissUnreadPromotionsIcon = (): TypedThunk => (dispatch, getState) => {
  const state = getState()
  const unvisitedPromotionsIconDismissed = isUnvisitedPromotionsIconDismissed(state)
  const unreadPromotionsAmount = getUnreadPromotionsAmount(state)

  if (unreadPromotionsAmount > 0 && !unvisitedPromotionsIconDismissed) {
    dispatch(setUnvisitedPromotionsIconDismissed({ dismissed: true }))
  }
}

// https://github.com/reduxjs/redux-toolkit/issues/489 not possible to use second parameter without first
export const fetchAllPromotions = createAppAsyncThunk(
  'promotions/fetchAllPromotions',
  async (sweepstakeEnabled: boolean, thunkAPI) => {
    const enabledCurrency = sweepstakeEnabled ? 'SC' : 'GC'

    const response = await thunkAPI.extra.fetch(`${ROUTES.PROMOTIONS_API}?currency=${enabledCurrency}`, null, {
      method: 'get',
    })

    if (response.ok) {
      return response.json()
    }

    return []
  }
)

export const fetchVisitedPromotions = createAppAsyncThunk(
  'promotions/fetchVisitedPromotions',
  async (payload: string[], options) => {
    const data: GetVisitedPromotionsRequest = { type: 'GetVisitedPromotionsRequest' }
    const visitedPromotionsResponse = await options.extra.gateway.emit<GetVisitedPromotionsResponse>(data)

    return visitedPromotionsResponse.visitedPromotions.map(({ id }) => id)
  }
)
