import { trackOptimizelyEvent } from '@patrianna-payments/analytics/optimizely/events'

import BuilderEventService from 'analyticActions/builder/events'
import { trackEvent } from 'config/analytic'

type TrackDataType = {
  amount: number
  currency: string
  offerCode: string
  userId: string
  provider: string
  city?: string
  zip?: string
  external_id?: string
  transactionTotal?: number
  // reddit pixel data
  transactionId?: string
  value?: number
}

export const trackFirstPurchase = ({
  offerCode,
  city,
  zip,
  external_id,
  value,
  transactionId,
  transactionTotal,
  userId,
  provider,
  currency,
}: TrackDataType) => {
  trackEvent(
    'first_purchase',
    {
      label: offerCode,
      city,
      zip,
      external_id,
      transactionTotal,
      transactionId,
      value,
      currency,
      content_id: transactionId,
    },
    { tiktok: true }
  )
  BuilderEventService.trackEvent('first_purchase')
  trackOptimizelyEvent('firstPurchase', {
    account_id: userId,
    amount: Number(transactionTotal),
    packageName: offerCode,
    provider,
  })
}
