import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

const getGames = (state: ReduxState) => state.games
export const gamesLoadingSelector = createSelector(getGames, (games) => games.loading)
export const gamesLoadedSelector = createSelector(getGames, (games) => games.loaded)
const getCategorizedGamesSelector = createSelector(getGames, (games) => games.games)
export const allGamesSelector = createSelector(getGames, (games) => games.allGames)

export const allVisibleGamesSelector = createSelector(allGamesSelector, (games) => games.filter((it) => !it.hidden))

export const getAllGamesProductsSelector = createSelector(getCategorizedGamesSelector, (categorizedProducts) => {
  if (categorizedProducts) {
    return Object.values(categorizedProducts).reduce((acc, products) => {
      return acc.concat(products)
    }, [] as GameProduct[])
  }

  return []
})
